import React from 'react';
import { useTheme } from 'styled-components';
import { TableRow, TableData, LabelButton, Label, Input } from '../../atoms';
import {
    StyledCapitalize,
    StyledDOBLabel,
    StyledHorizontalSpacer,
    StyledHorizontalWrapper,
    StyledStatusLabel,
    StyledSubTitle,
    StyledTableActionsContainer,
    StyledTextWrapBreakWord,
    StyledTextWrapBreakId
} from './styles';
import { IMemberDetailsRow } from './types';
import { StyledMargin } from '../../pages/SPAContainer/pages/MemberDetails/styles';
import useFormInput from '../../hooks/useFormInput';
import { useToast } from '../../hooks/useToast';
import { updateEmployeeIdAPI } from '../../../adapters';
import { isDeleteMembersEnabled, isEmployeeIdEditEnabled } from '../../../utils/featureFlags';
import { DateFormatUtils } from '@loophealth/loop-ui-web-library';

const MemberDetailsRow: React.FunctionComponent<IMemberDetailsRow> = ({
    user,
    index,
    showUserPolicies,
    deleteMember
}) => {
    const toast = useToast();
    const employeeId = useFormInput(user.employeeId);
    const theme = useTheme();
    const handlePolicyDetails = () => {
        showUserPolicies(user.userId);
    };
    const handleDeleteMember = () => {
        if (isDeleteMembersEnabled) {
            deleteMember(user);
        }
    };

    const editEmployeeId = async () => {
        // call edit employee API
        if (isEmployeeIdEditEnabled) {
            if (employeeId.value === '') {
                toast?.displayToast('error', 'Update Error', `Employee Id can't be empty`, 8000);
                employeeId.setErrorText(`Employee Id can't be empty`);
                return;
            }
            toast?.displayToast('warning', 'Updating EmployeeId', 'It can take a while...', 4000);
            try {
                await updateEmployeeIdAPI(user.userId, employeeId.value, user.employer);
                toast?.displayToast('success', 'Update Successful', 'Employee Id updated successfully!', 8000);
            } catch (error: any) {
                toast?.displayToast('error', 'Update Error', error.message.toString(), 8000);
                employeeId.setErrorText(error.message.toString());
            }
        }
    };

    return (
        <TableRow>
            <TableData>{index + 1}</TableData>
            <TableData index>
                <StyledCapitalize>
                    <b>{user.firstName + ' ' + user.lastName}</b>
                </StyledCapitalize>
                userId: <StyledSubTitle>{user.userId}</StyledSubTitle>
            </TableData>
            <TableData>
                <StyledTextWrapBreakWord>Mobile: {user.mobile || <b>N/A</b>}</StyledTextWrapBreakWord>
                <StyledTextWrapBreakWord>Email: {user.email || <b>N/A</b>}</StyledTextWrapBreakWord>
            </TableData>
            <TableData align="center">
                <StyledCapitalize>{user.relationship}</StyledCapitalize>
            </TableData>
            <TableData align="center">
                {user.gender === 'female' ? 'F' : user.gender === 'male' ? 'M' : 'N/A'}
            </TableData>
            <TableData align="center">
                {user.dob && new DateFormatUtils().formatDate(user.dob)}
                <br />
                <StyledDOBLabel>(DD MMM YYYY)</StyledDOBLabel>
            </TableData>
            {/* <TableData align="center">{user.employeeId || 'N/A'}</TableData> */}
            <TableData align={(isEmployeeIdEditEnabled && undefined) || 'center'}>
                {isEmployeeIdEditEnabled && (
                    <StyledHorizontalWrapper>
                        <Input
                            name={'employeeId'}
                            placeholder={'Employee Id'}
                            {...employeeId}
                            onChange={(e) => {
                                employeeId.onChange(e);
                            }}
                        />
                        <StyledHorizontalSpacer />
                        <LabelButton onClick={editEmployeeId} color={theme.colors.primary}>
                            Save
                        </LabelButton>
                    </StyledHorizontalWrapper>
                )}
                {!isEmployeeIdEditEnabled && user.employeeId}
            </TableData>
            <TableData align="center">
                <StyledTextWrapBreakId>{user.parentId || '-'}</StyledTextWrapBreakId>
            </TableData>
            <TableData>
                <StyledStatusLabel>
                    <Label isActive={user.active}>{user.active ? 'ACTIVE' : 'INACTIVE'}</Label>
                </StyledStatusLabel>
            </TableData>
            <TableData>
                <StyledTableActionsContainer>
                    <StyledMargin>
                        <LabelButton onClick={handlePolicyDetails} color={theme.colors.primary}>
                            Policy Details
                        </LabelButton>
                    </StyledMargin>
                    {isDeleteMembersEnabled && (
                        <StyledMargin>
                            <LabelButton onClick={handleDeleteMember} color={theme.colors.primary}>
                                Delete Member
                            </LabelButton>
                        </StyledMargin>
                    )}
                </StyledTableActionsContainer>
            </TableData>
        </TableRow>
    );
};

export default MemberDetailsRow;
